<template>
    <div class="text-center" style="direction:ltr;margin-top:100px;">
        <h1>404: This page not exist | أنت تحاول الوصول إلى صفحة غير موجوده </h1>
        <a :href="url">click hear to back to hom | اضغط هنا للعودة</a>
    </div>
</template>

<script>

export default {
    data() {
        return {
            url: '',
        }    
    },
    created() {
        this.url = localStorage.getItem('redirectURl');
    }
}
</script>
